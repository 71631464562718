.custom-scrollbar-primary {
  scrollbar-color: theme('colors.french-blue.DEFAULT') theme('colors.grey.100');
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    @apply w-2;
  }

  &::-webkit-scrollbar-track {
    @apply bg-grey-100 rounded-lg;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-french-blue rounded-lg;
  }

  &::-webkit-scrollbar-button {
    @apply hidden;
  }
}

.custom-scrollbar-primary-thin {
  scrollbar-color: theme('colors.french-blue.DEFAULT') theme('colors.grey.50');
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-track {
    @apply bg-grey-50 rounded-lg;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-french-blue rounded-lg;
  }

  &::-webkit-scrollbar-button {
    @apply hidden;
  }
}
