@import 'typography-mixins';

body * {
  @apply subpixel-antialiased font-normal font-body leading-normal text-md tracking-normal;

  @screen lg {
    @apply antialiased tracking-tight;
  }
}

.page-heading-one {
  @include page-heading-one;
}

.page-heading-two {
  @include page-heading-two;
}

.page-heading-three {
  @include page-heading-three;
}

.page-heading-four {
  @include page-heading-four;
}

.page-heading-five {
  @include page-heading-five;
}

.page-heading-six {
  @include page-heading-six;
}
