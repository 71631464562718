@import 'swiper/swiper.scss';
@import 'swiper/components/pagination/pagination.scss';
@import 'swiper/components/navigation/navigation.scss';

.swiper-container {
  &.mobile-overhang {
    @apply -mx-4;

    @screen md {
      @apply mx-0;
    }
  }

  .swiper-slide {
    height: auto;
  }
}

.swiper-pagination {
  @apply bottom-0 pb-4;

  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .swiper-pagination-bullet-active {
    @apply bg-french-blue;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}