input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='password']::-ms-reveal {
  display: none;
}

input[type='search'],
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='url'] {
  -webkit-appearance: none;
}
