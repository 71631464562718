@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Light.ttf') format('truetype'),
    url('/fonts/Inter/Inter-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Regular.ttf') format('truetype'),
    url('/fonts/Inter/Inter-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Bold.ttf') format('truetype'),
    url('/fonts/Inter/Inter-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Black.ttf') format('truetype'),
    url('/fonts/Inter/Inter-Black.otf') format('opentype');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}