/** Tailwind imports **/
@tailwind base;
@tailwind components;
@tailwind utilities;

/** Icomoon imports **/
@import '../../public/icons/style.css';

/** Other imports **/
@import './fonts.scss';
@import './inputs.scss';
@import './react-datepicker.scss';
@import './scrollbars.scss';
@import './swiper.scss';
@import './typography.scss';
