@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker-wrapper {
  @apply absolute invisible top-0 w-full -z-1;
}

.react-datepicker-popper {
  @apply w-full;
  padding-top: 0 !important; // !important to overwrite default styles
  top: theme('padding.8') !important; // !important to overwrite default styles
}

.react-datepicker-inline-wrapper {
  .react-datepicker {
    @apply shadow-none;

    &__header {
      border: none !important; // !important to overwrite default styles
      padding: 0 !important; // !important to overwrite default styles
    }

    &__current-month {
      background-color: theme('colors.light-blue.DEFAULT') !important; // !important to overwrite default styles
      border-radius: theme('borderRadius.lg') !important; // !important to overwrite default styles;
      padding: theme('padding.4') 0 !important; // !important to overwrite default styles
    }

    &__day-names {
      padding: theme('padding.4') 0 0 !important; // !important to overwrite default styles
    }

    &__day-name {
      @apply flex font-bold h-12 items-center justify-center;
      color: theme('colors.grey.600') !important; // !important to overwrite default styles
      display: flex !important; // !important to overwrite default styles
      width: 3rem !important; // !important to overwrite default styles
    }

    &__day--keyboard-selected {
      background-color: transparent !important; // !important to overwrite default styles;
      color: theme('colors.black.DEFAULT') !important; // !important to overwrite default styles
    }
    
    &__day--highlighted {
      background-color: theme('colors.french-blue.DEFAULT') !important; // !important to overwrite default styles
      color: theme('colors.white.DEFAULT') !important; // !important to overwrite default styles
    }

    &__day--disabled {
      color: theme('colors.grey.200') !important; // !important to overwrite default styles
      text-decoration: line-through !important; // !important to overwrite default styles
      cursor: default !important; // !important to overwrite default styles
    }

    &__month {
      background-color: theme('colors.grey.50') !important; // !important to overwrite default styles
      border-radius: theme('borderRadius.lg') !important; // !important to overwrite default styles;
      margin: 0.4rem 0 !important; // !important to overwrite default styles;
      padding: 0.4rem !important; // !important to overwrite default styles;
    }

    &__navigation-icon--next {
      left: -0.5rem !important; // !important to overwrite default styles;
    }

    &__navigation-icon--previous {
      right: -0.5rem !important; // !important to overwrite default styles;
    }
  }
}

.react-datepicker {
  border: none !important; // !important to overwrite default styles;
  border-bottom-left-radius: theme('borderRadius.lg') !important; // !important to overwrite default styles;
  border-bottom-right-radius: theme('borderRadius.lg') !important; // !important to overwrite default styles;
  border-top-left-radius: none !important; // !important to overwrite default styles;
  border-top-right-radius: none !important; // !important to overwrite default styles;
  @apply shadow-lg w-full;

  &__triangle {
    @apply hidden;
  }

  &__header {
    background-color: theme('colors.white.DEFAULT') !important; // !important to overwrite default styles
    border-color: theme('colors.grey.100') !important; // !important to overwrite default styles
    padding: theme('padding.4') 0 !important; // !important to overwrite default styles
    @apply w-full;
  }

  &__month-container {
    @apply w-full;
  }

  &__day-names {
    margin-left: 0.4rem !important;
    margin-right: 0.4rem !important;
  }

  &__month-wrapper,
  &__week,
  &__day-names {
    @apply flex justify-around;
  }

  &__month-text,
  &__day {
    @apply flex h-12 items-center justify-center uppercase;
    border-radius: 9999px !important; // !important to overwrite default styles
    display: flex !important; // !important to overwrite default styles
    width: 3rem !important; // !important to overwrite default styles
  }

  &__navigation {
    top: 0.925rem !important; // !important to overwrite default styles
  }

  &__navigation-icon {
    width: 0 !important; // !important to overwrite default styles;
  }

  &__navigation-icon:before {
    border-color: theme('colors.french-blue.DEFAULT') !important; // !important to overwrite default styles
    border-width: 2px 2px 0 0 !important; // !important to overwrite default styles
    height: 12px !important; // !important to overwrite default styles
    width: 12px !important; // !important to overwrite default styles
  }
}