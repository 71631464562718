@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8afo4x');
  src:  url('fonts/icomoon.eot?8afo4x#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8afo4x') format('truetype'),
    url('fonts/icomoon.woff?8afo4x') format('woff'),
    url('fonts/icomoon.svg?8afo4x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-replace:before {
  content: "\e900";
}
.icon-account-circle:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e902";
}
.icon-add-circle:before {
  content: "\e903";
}
.icon-alert-circle:before {
  content: "\e904";
}
.icon-basket:before {
  content: "\e905";
}
.icon-basket-outline:before {
  content: "\e906";
}
.icon-car:before {
  content: "\e90d";
}
.icon-check:before {
  content: "\e91c";
}
.icon-check-circle:before {
  content: "\e90c";
}
.icon-chevron-down:before {
  content: "\e907";
}
.icon-chevron-left:before {
  content: "\e91a";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-chevron-up:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e910";
}
.icon-close-circle:before {
  content: "\e920";
}
.icon-download:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-email:before {
  content: "\e91b";
}
.icon-eye:before {
  content: "\e91e";
}
.icon-eye-crossed:before {
  content: "\e91f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-fax:before {
  content: "\e90a";
}
.icon-file:before {
  content: "\e922";
}
.icon-filter:before {
  content: "\e923";
}
.icon-fullscreen:before {
  content: "\e925";
}
.icon-info-circle:before {
  content: "\e916";
}
.icon-linkedin:before {
  content: "\eaca";
}
.icon-megaphone:before {
  content: "\e915";
}
.icon-menu:before {
  content: "\e91d";
}
.icon-open:before {
  content: "\e914";
}
.icon-orders:before {
  content: "\e913";
}
.icon-phone:before {
  content: "\e90b";
}
.icon-pin:before {
  content: "\e919";
}
.icon-play:before {
  content: "\e918";
}
.icon-question-circle:before {
  content: "\e917";
}
.icon-search:before {
  content: "\e90f";
}
.icon-star:before {
  content: "\e912";
}
.icon-star-outline:before {
  content: "\e921";
}
.icon-trash:before {
  content: "\e924";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
